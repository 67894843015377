import "./Posts.css"

function Posts(){
    return (
        <div className="Home">
            <div className="Posts">
                <div className="PostHeader">All My posts</div>
                <div className="Post">
                    New Post
                </div>
                <div className="Post">
                    Another Post
                </div>
                <div className="Post">
                    Another Post
                </div>
                <div className="Post">
                    Another Post
                </div>
            </div>
        </div>
    )
}

export default Posts