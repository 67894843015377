import React from 'react';
import './Home.css';
import { User, Briefcase, Award, Code, Mail, Phone, Linkedin } from 'lucide-react';

const HomeBar = () => {
  return (
    <div className="home-container">
      <header className="hero">
        <h1>Zain Ullah Khan</h1>
        <p>Senior Backend Engineer</p>
      </header>

      <section className="summary-section">
        <h2><User size={24} /> Professional Summary</h2>
        <p>Backend developer with over 4 years of experience designing and building scalable, high-performance systems. Focused on optimizing performance, enhancing reliability, and addressing challenges related to system scalability, particularly within distributed systems.</p>
      </section>

      <section className="skills-section">
        <h2><Code size={24} /> Skills</h2>
        <div className="skills-grid">
          <div className="skill-tile">
            <h3>Languages</h3>
            <p>Python, Java, JavaScript, C++</p>
          </div>
          <div className="skill-tile">
            <h3>Frameworks/Technologies</h3>
            <p>Django, Flask, Falcon, Celery, Kafka, Redis, PostgreSQL, MongoDB</p>
          </div>
          <div className="skill-tile">
            <h3>Cloud/DevOps</h3>
            <p>AWS (EC2, S3, Lambda, ELB, SQS, CloudFront, ElasticCache), Docker, Kubernetes</p>
          </div>
          <div className="skill-tile">
            <h3>Monitoring</h3>
            <p>Sentry, New Relic, Grafana, Redash</p>
          </div>
        </div>
      </section>

      <section className="experience-section">
        <h2><Briefcase size={24} /> Work Experience</h2>
        <div className="experience-tile">
          <h3>Senior Backend Engineer</h3>
          <p>MoEngage India Pvt. Ltd. | Oct 2022 - Present</p>
          <ul>
            <li>Optimized database queries, improving card viewing performance by 80%</li>
            <li>Developed critical features like Audit Logs and Delivery Funnel Dashboard</li>
            <li>Led development of periodic campaigns with high adoption rates</li>
          </ul>
        </div>
        {/* Add more experience tiles as needed */}
      </section>

      <section className="awards-section">
        <h2><Award size={24} /> Awards & Achievements</h2>
        <ul>
          <li>Spot Award, MoEngage 2023</li>
          <li>Special Achievement Award, TCS 2022</li>
          <li>AIR 54 in Codevita organized by TCS, 2020</li>
        </ul>
      </section>

      <section className="contact-section">
        <h2><Mail size={24} /> Contact</h2>
        <div className="contact-grid">
          <a href="mailto:zainkhanjune@gmail.com" className="contact-tile">
            <Mail size={20} /> Email
          </a>
          <a href="tel:+917678591787" className="contact-tile">
            <Phone size={20} /> Phone
          </a>
          <a href="https://www.linkedin.com/in/zain-khan-dev" target="_blank" rel="noopener noreferrer" className="contact-tile">
            <Linkedin size={20} /> LinkedIn
          </a>
          <a href="https://github.com/zain-khan-dev" target="_blank" rel="noopener noreferrer" className="contact-tile">
            <Code size={20} /> GitHub
          </a>
        </div>
      </section>
    </div>
  );
};

export default HomeBar;